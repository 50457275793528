<template>
  <v-container class="d-flex" style="height: 100%">
    <v-card v-if="isLoaded" class="ma-auto" max-width="960px">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <div>{{ $t("labels.company_name") }}:</div>
            <div class="font-weight-medium black--text">
              {{ customer.company_name }}
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.tax_code") }}:</div>
            <div class="font-weight-medium black--text">
              {{ customer.tax_id }}
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.email") }}:</div>
            <div class="font-weight-medium black--text">
              {{ customer.email }}
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.bank_account_number") }}:</div>
            <div class="font-weight-medium black--text">
              {{ customer.bank_account_number }}
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.bank_account_owner") }}:</div>
            <div class="font-weight-medium black--text">
              {{ customer.bank_account_owner_name }}
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.bank_name") }}:</div>
            <div class="font-weight-medium black--text">
              {{ customer.bank_name }}
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.bank_branch") }}:</div>
            <div class="font-weight-medium black--text">
              {{ customer.bank_branch_name }}
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.hotline") }}:</div>
            <div class="font-weight-medium black--text">
              {{ customer.hotline }}
            </div>
          </v-col>
        </v-row>

        <v-row>
          <!-- <div>
              <v-text-field
                v-model="customer.hotline"
                :placeholder="$t('labels.hotline')"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </div> -->

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.email_report") }}:</div>
            <div>
              <SelectTag
                :placeholder="$t('labels.email_report')"
                :default-value="customer.email_cc"
                name="email_cc"
                @onChange="onSelectChange"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.email_receipt") }}:</div>
            <div>
              <SelectTag
                :placeholder="$t('labels.email_receipt')"
                :default-value="customer.email_goods_receipt"
                name="email_goods_receipt"
                @onChange="onSelectChange"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.email_return") }}:</div>
            <div>
              <SelectTag
                :placeholder="$t('labels.email_return')"
                :default-value="customer.email_goods_return"
                name="email_goods_return"
                @onChange="onSelectChange"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.email_goods_quantity") }}:</div>
            <div>
              <SelectTag
                :placeholder="$t('labels.email_goods_quantity')"
                :default-value="customer.email_goods_quantity"
                name="email_goods_quantity"
                @onChange="onSelectChange"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.email_goods_report_available") }}:</div>
            <div>
              <SelectTag
                :placeholder="$t('labels.email_goods_report_available')"
                :default-value="customer.email_goods_report_available"
                name="email_goods_report_available"
                @onChange="onSelectChange"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div>{{ $t("labels.email_goods_report_available_month") }}:</div>
            <div>
              <SelectTag
                :placeholder="$t('labels.email_goods_report_available_month')"
                :default-value="customer.email_goods_report_available_month"
                name="email_goods_report_available_month"
                @onChange="onSelectChange"
              />
            </div>
          </v-col>

          <v-col cols="12" sm="4">
            <div>{{ $t("labels.old_password") }}:</div>
            <div>
              <v-text-field
                v-model="customer.old_password"
                :placeholder="$t('labels.old_password')"
                type="password"
                autocomplete="off"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="12" sm="4">
            <div>{{ $t("labels.new_password") }}:</div>
            <div>
              <v-text-field
                :disabled="!customer.old_password"
                v-model="customer.new_password"
                :placeholder="$t('labels.new_password')"
                type="password"
                autocomplete="off"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="12" sm="4">
            <div>{{ $t("labels.confirm_password") }}:</div>
            <div>
              <v-text-field
                :disabled="
                  !customer.new_password || customer.new_password.length < 6
                "
                v-model="customer.confirm_new_password"
                :placeholder="$t('labels.confirm_password')"
                type="password"
                autocomplete="off"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="12" sm="12">
            <v-btn
              color="success"
              block
              :disabled="isDisabledBtn"
              @click="updateInfo"
              >{{ $t("labels.save") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "MainAccount",
  components: {
    SelectTag: () => import("@/components/common/SelectTag"),
  },
  data: () => ({
    customer: {},
    isLoading: false,
    isLoaded: false,
  }),
  computed: {
    isDisabledBtn() {
      if (this.customer.old_password) {
        if (
          !this.customer.new_password ||
          this.customer.new_password.length < 6
        ) {
          return true;
        }
        if (this.customer.new_password !== this.customer.confirm_new_password) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    onSelectChange(item) {
      const value = item.value.join(";");
      this.customer = { ...this.customer, [item.name]: value };
    },
    async getInfo() {
      try {
        const { data } = await httpClient.post("/company-info");
        this.customer = { ...data };
        this.isLoaded = true;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async updateInfo() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-company-info", this.customer);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
